import styled from "styled-components";

export const CardsWrapper = styled.div`
  --card-grid-gap: 30px;

  width: 100%;
  height: auto;
  display: grid;

  padding: var(--card-grid-gap) 0;

  grid-template-columns: 1fr 150px 150px 1fr 150px 150px 1fr;
  grid-template-rows: auto;
  gap: var(--card-grid-gap);

  @media screen and (max-width: 890px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 750px) {
    display: block;
    max-width: 350px;
    width: 90%;
    margin: auto;
  }
`;

export const CardOuter = styled.div`
  display: block;
  height: auto;
  padding-top: 15px;

  &:nth-child(even) {
    grid-column-start: 5;
    grid-column-end: 7;

    @media screen and (max-width: 890px) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  &:nth-child(odd) {
    grid-column-start: 2;
    grid-column-end: 4;

    @media screen and (max-width: 890px) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  @media screen and (max-width: 750px) {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

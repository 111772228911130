import styled from "styled-components";
import { Heading1 } from "../../theme/typography/headers";

export const HeaderWrapper = styled.header<{ url?: string }>`
  background: ${(props) => `url(${props.url})`};
  background-size: cover;
  background-position-y: center;
  padding: 0;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const HeaderInner = styled.nav`
  width: 100%;
  height: auto;
  background: rgba(107, 63, 20, 0.4);
`;

export const HeaderTopCircle = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 2% 0;
  margin-bottom: 30vh;

  img {
    display: block;
    max-width: 250px;
    width: 50%;
    height: auto;
    margin: auto;
    position: relative;
    z-index: 10;
  }

  object {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 1020px) {
      width: 1400px;
      left: 50%;
      margin-left: -700px;
    }
  }
`;

export const HeaderBottomCircle = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 0 2% 0;
  margin-bottom: -2px;

  .content {
    text-align: center;
    display: block;
    max-width: 550px;
    width: 90%;
    height: auto;
    margin: auto;
    position: relative;
    z-index: 10;

    &.isProgram {
      max-width: 650px;
      padding-top: 20px;

      ${Heading1} {
        padding-top: 10px;
        font-size: 45px;

        @media screen and (max-width: 750px) {
          font-size: 30px;
          padding-bottom: 40px;
        }
      }
    }
  }

  object {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 1020px) {
      width: 1400px;
      left: 50%;
      margin-left: -700px;
    }
  }

  @media screen and (max-width: 990px) {
    padding: 50px 0 0 0;
  }
`;

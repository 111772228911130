import styled from "styled-components";
import { Heading2, Heading4 } from "../typography/headers";

export const PageWrapper = styled.article`
  display: block;
  width: 100%;
  
  &.white {
    background: var(--background);
  }
`;

export const ContentSection = styled.section`
  padding: 5% 0;
  width: 90%;
  max-width: 1030px;
  margin: auto;

  ${Heading2} {
    margin-bottom: 5px;
  }

  ${Heading4} {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 750px) {
      font-size: 18px;
    }
  }
`;

import React from "react";
import {
  HeaderContent,
  HeaderImage,
  HeaderLogo,
  HeaderWrapper,
} from "./Header.styles";
import logo from "../../logo.svg";
import { Heading1, Heading6 } from "../../theme/typography/headers";

export function Header(props: {
  url: string;
  title?: string;
  isProgram?: boolean;
  year?: number;
  programName?: string;
}) {
  return (
    <HeaderWrapper>
      <HeaderLogo>
        <img src={logo} alt="" />
      </HeaderLogo>

      <HeaderImage>
        <img src={props.url} alt="" />
      </HeaderImage>

      <HeaderContent>
        <Heading6>
          Dramabuzz Senior Presents its {props.year} production of
        </Heading6>
        <Heading1>{props.programName}</Heading1>
      </HeaderContent>
    </HeaderWrapper>
  );
}

import React, { PropsWithChildren } from "react";
import { Button } from "../button/Button";
import { ModalInner, ModalWrapper } from "./modal.styles";

export function Modal(
  props: PropsWithChildren<{ isOpen: boolean; onCloseClicked?: () => void }>
) {
  if (props.isOpen) {
    return (
      <ModalWrapper>
        <ModalInner>
          {props.children}

          <br />

          <div style={{ textAlign: "center", paddingBottom: 10 }}>
            <Button label="Close" onClick={props.onCloseClicked} />
          </div>
        </ModalInner>
      </ModalWrapper>
    );
  } else return <React.Fragment />;
}

import React from "react";
import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import { Heading2, Heading4 } from "../../theme/typography/headers";
import { ButtonBlock } from "../../components/button-block/button-block";

export function FeeExplainerPage(): JSX.Element {
  return (
    <PageWrapper className="white">
      <TextHeader pageTitle="FAQ About Our Fees" hideBottomImage />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>The Quick of It</Heading2>

        <Heading4>
          It might be natural to wonder what the purpose of our fees is. The
          answer is easy and straightforward - the fees serve a twofold purpose.
          First, they serve as a buy-in to ensure that those on the cast are
          committed to being there. This is a club and we all depend on each
          other to be committed to the group. Secondly, and more importantly,
          these fees are what allow us to put on our productions. It costs money
          to put on plays and Dramabuzz is a completely voluntary group. We put
          together everything from our costumes to our sets, and we do that with
          the money received from the membership fees. None of this money goes
          to the directors.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Why are there two fees?</Heading2>

        <Heading4>
          It is reasonable to ask why there is a membership fee as well as an
          auditioning fee (deposit). The auditioning fee is to be paid before
          auditions take place and will be returned if you decide that you no
          longer want to participate. This is only applicable until the cast
          list has been announced. It is better to think of there being one fe
          that is simply split into to payments - the "membership" fee of
          R500.00 is only required once you have joined the cast as a member,
          that is, when you have a role in the production.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Is the fee required?</Heading2>

        <Heading4>
          Yes, the fee is required for all members of the cast. If you are
          worried about whether or not you will be able to pay the fees, we are
          willing to discuss payment terms to reduce the load. If you need to
          make an arrangement, please email{" "}
          <a href="mailto:admin@dramabuzzsenior.co.za">
            admin@dramabuzzsenior.co.za
          </a>{" "}
          and we will do our best to assist you.
        </Heading4>
      </ContentSection>

      <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock>
    </PageWrapper>
  );
}

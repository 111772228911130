import React from "react";
import { CastCard } from "../../../components/cast-card/cast-card";
import { Navigation } from "../../../components/navigation/navigation";
import { PageWrapper, ContentSection } from "../../../theme/layout/wrappers";
import { Heading2, Heading4 } from "../../../theme/typography/headers";
import {
  CardsWrapper,
  CardOuter,
} from "../../the-directors/the-directors.styles";
import { Header } from "../../../components/header/Header";
import { RoleList } from "./2024.data";
import { ButtonBlock } from "../../../components/button-block/button-block";

export function Year2024(): JSX.Element {
  return (
    <PageWrapper className="white">
      <Header
        isProgram
        url={"/headers/2024program.png"}
        programName="Toad of Toad Hall"
        year={2024}
      />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>Welcome</Heading2>

        <Heading4>
          Dramabuzz Senior presents its third theatrical production -{" "}
          <strong>Toad of Toad Hall</strong>. This delightful and witty play was
          adapted by A.A. Milne from the much beloved Wind in the Willows by
          Kenneth Grahame. Filled with daring plots, scrumptious luncheons and
          cozy fireside chats, this play will take you into the world of the
          lovable animals who live by the river and in the forest. Come laugh
          with Mole and Ratty and Toady and Badger - come experience their
          world.
        </Heading4>

        <Heading4>
          The show itself is appropriate for all ages and can be enjoyed by
          young and old alike. It is filled with a timeless and charming wit
          that can only come from an A.A. Milne play. If you are wondering
          whether you will like the story, I'll let the author of the play speak
          for himself.
        </Heading4>

        <Heading4>
          <blockquote>
            One does not argue about The Wind in the Willows. The young man
            gives it to the girl with whom he is in love, and if she does not
            like it, asks her to return his letters. The older man tries it on
            his nephew, and alters his will accordingly
          </blockquote>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>About the Play</Heading2>

        <Heading4>
          "Toad of Toad Hall" is a delightful theatrical adaptation of Kenneth
          Grahame's beloved children's novel, "The Wind in the Willows." The
          play, written by A. A. Milne, brings to life the charming characters
          of Toad, Rat, Mole, and Badger as they embark on whimsical adventures
          in the English countryside. At the heart of the story is the
          flamboyant and impulsive Mr. Toad, whose obsession with motorcars
          leads him into trouble with the law. With the help of his steadfast
          friends, Toad learns valuable lessons about friendship,
          responsibility, and the importance of humility. Set against the
          backdrop of Toad Hall, the play captures the timeless appeal of
          Grahame's original tale, enchanting audiences with its humor, warmth,
          and sense of adventure.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>The Cast</Heading2>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/2024/arno.jpeg"
              roleName={"Mr. Toad"}
              memberName="Arno van den Bergh"
              characterSynopsis={RoleList.toad}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/phillip.jpeg"
              roleName={"Mr. Rat"}
              memberName="Phillip Vorster"
              characterSynopsis={RoleList.rat}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/andrea.jpeg"
              roleName={"Ms. Mole"}
              memberName="Andrea Venter"
              characterSynopsis={RoleList.mole}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/cois.jpeg"
              roleName={"Mr. Badger"}
              memberName="Francois Venter"
              characterSynopsis={RoleList.badger}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/david.jpeg"
              roleName={"Alfred"}
              memberName="David Wright"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/kristie.jpeg"
              roleName={"Marigold"}
              memberName="Kristie Dirks"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/danita.jpeg"
              roleName={"Nurse"}
              memberName="Danita van den Berg"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/luiz.jpeg"
              roleName={"Chief Weasel"}
              memberName="Luiz Hibberd"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/linmarie.jpeg"
              roleName={"Washerwoman"}
              memberName="Linmarie Dirks"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/arlene.jpeg"
              roleName={"The Honourable Judge"}
              memberName="Arlené Cilliers"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/anna.jpeg"
              roleName={"Usher/Police Officer"}
              memberName="Anna Britz"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/ruth.jpeg"
              roleName={"Phoebe"}
              memberName="Ruthanne Wright"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/sarah.jpeg"
              roleName={"Bargewoman"}
              memberName="Sarah Wright"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/tyler.jpeg"
              roleName={"Hench weasel"}
              memberName="Tyler Komen"
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/josh.jpeg"
              roleName={"Fox"}
              memberName="Josh Britz"
            />
          </CardOuter>
        </CardsWrapper>
      </ContentSection>

      <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock>

      <ContentSection>
        <Heading2>The Directors</Heading2>

        <Heading4>
          No show comes together on its own. Months of planning and admin work
          go into choosing a play, setting up practices, hosting auditions, and
          putting everything together. This is the responsibility of our
          directors.
        </Heading4>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/2024/tyler.jpeg"
              isSmall
              memberName={"Tyler Komen"}
              roleName={"Blocking and Staging"}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/andrea.jpeg"
              isSmall
              memberName={"Andrea Venter"}
              roleName={"Costumes and Sets"}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/2024/josh.jpeg"
              isSmall
              memberName={"Joshua Britz"}
              roleName={"Production and Admin"}
            />
          </CardOuter>
        </CardsWrapper>
      </ContentSection>

      <ContentSection>
        <Heading2>The Production Team</Heading2>

        <Heading4>
          In putting a show together, the production team is vitally important.
          From design to implementation, the production team helps to make the
          vision of the directors a reality and the world of the actors real.
        </Heading4>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/talitha.jpeg"
              isSmall
              memberName={"Talitha Komen"}
              roleName={"Creative Designer"}
            />
          </CardOuter>
        </CardsWrapper>
      </ContentSection>
    </PageWrapper>
  );
}

import classNames from "classnames";
import React, { useState } from "react";
import { Heading2, Heading3, Heading4 } from "../../theme/typography/headers";
import { Paragraph } from "../../theme/typography/paragraph";
import { Button } from "../button/Button";
import { Modal } from "../modal/modal";
import { ICastCardProps } from "./cast-card.models";
import {
  CastCardContent,
  CastCardImage,
  CastCardWrapper,
} from "./cast-card.styles";

export function CastCard(props: ICastCardProps): JSX.Element {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <CastCardWrapper>
        <CastCardImage>
          <img src={props.url} alt={props.memberName} />
        </CastCardImage>

        <CastCardContent>
          {!!props.memberName && <Heading4>{props.memberName}</Heading4>}
          <Heading2 className={classNames({ isSmall: props.isSmall })}>
            {props.roleName}
          </Heading2>
          {!!props.characterSynopsis ? (
            <React.Fragment>
              <Button
                theme="accent"
                label={"Character Synopsis"}
                onClick={() => setModalIsOpen(true)}
              />
              <div style={{ height: 35 }} />
            </React.Fragment>
          ) : (
            <></>
          )}
          {!!props.bio && <Paragraph>{props.bio}</Paragraph>}
        </CastCardContent>
      </CastCardWrapper>

      {!!props.characterSynopsis && (
        <Modal
          isOpen={modalIsOpen}
          onCloseClicked={() => setModalIsOpen(false)}
        >
          <Heading3>{props.roleName}</Heading3>

          <Heading4 style={{ marginBottom: 10 }}>
            {props.characterSynopsis}
          </Heading4>
        </Modal>
      )}
    </React.Fragment>
  );
}

import React from "react";
import { ButtonBlock } from "../../components/button-block/button-block";
import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import { Heading2, Heading4 } from "../../theme/typography/headers";

export function DonatePage(): JSX.Element {
  return (
    <PageWrapper className="white">
      <TextHeader pageTitle="Donate" />

      <hr />

      <Navigation light />

      <ContentSection>
        <Heading2>Why We Ask For Donations</Heading2>

        <Heading4>
          We are a community driven, self-funded group. Our goal is to provided
          wholesome and enjoyable entertainment for audiences, and a fun, safe
          environment for young adults who are interested in theatre. Any money
          that we make off of ticket sales and donations go directly towards
          funding future productions. No one in the group profits monetarily
          from any part show.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>How You Can Support Us</Heading2>

        <ButtonBlock
          noPadding
          alignLeft
          buttonLabel="Donate Now on Quicket"
          href="https://www.quicket.co.za/event/169825/collect"
          theme="quicket"
        >
          Of course, the ultimate way you can support us is by purchasing show
          tickets and being and awesome audience member. Outside of that, we
          also have a way to give donations to the group, using the quicket
          platform. We are also open to sponsorships. If you are interested in
          becoming a sponsor, please email us at{" "}
          <a
            href="mailto:sponsor@dramabuzzsenior.co.za"
            style={{ wordBreak: "break-all" }}
          >
            sponsor@dramabuzzsenior.co.za
          </a>
          for more information.
        </ButtonBlock>
      </ContentSection>
    </PageWrapper>
  );
}

import styled from "styled-components";
import { Heading4 } from "../../theme/typography/headers";

export const ButtonBlockWrapper = styled.div`
  width: 90%;
  max-width: 1030px;
  margin: auto;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 5% 0;

  ${Heading4} {
    margin-bottom: 50px;
  }

  &.noPadding {
    padding: 0;
  }

  &.alignLeft {
    text-align: left;
    max-width: 100%;
    width: 100%;
  }
`;

import styled from "styled-components";
import { Heading1 } from "../../theme/typography/headers";

export const HeaderWrapper = styled.header`
  background: var(--background);
  background-size: cover;
  background-position-y: center;
  padding: 0;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`;

export const HeaderInner = styled.nav`
  width: 100%;
  height: auto;

  ${Heading1} {
      text-align: center;
      padding: 5% 0;
      font-size: 80px
  }
`;

export const HeaderTopCircle = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 2% 0;

  img {
    display: block;
    max-width: 250px;
    width: 50%;
    height: auto;
    margin: auto;
    position: relative;
    z-index: 10;
  }

  object {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 1020px) {
      width: 1400px;
      left: 50%;
      margin-left: -700px;
    }
  }
`;

export const HeaderBottomCircle = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  padding: 50px 0 2% 0;
  margin-bottom: -2px;

  object {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    @media screen and (max-width: 1020px) {
      width: 1400px;
      left: 50%;
      margin-left: -700px;
    }
  }

  @media screen and (max-width: 990px) {
    padding: 50px 0 0 0;
  }
`;

import React from "react";
import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import { Heading2, Heading4 } from "../../theme/typography/headers";
import { ButtonBlock } from "../../components/button-block/button-block";

export function OurPoliciesPage(): JSX.Element {
  return (
    <PageWrapper className="white">
      <TextHeader pageTitle="Our Policies" hideBottomImage />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>Size of the Group</Heading2>

        <Heading4>
          The size of the group will be limited and set based on the size of the
          cast required for a production.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Age and Participation Criteria</Heading2>

        <Heading4>
          <ul>
            <li>
              Dramabuzz Senior is open to adults between the ages of 18 and 30.
              Candidates should be within the correct age range by the time of
              the performance.{" "}
              <strong>No exceptions will be made to this rule.</strong>
            </li>

            <li>
              Admission to the group will be determined by an auditioning
              process each year. As well as the auditioning process, the
              following criteria will be considered for members wishing to join:
              <ul>
                <li>
                  A; if you are a former member of the Junior Dramabuzz group,
                  you will be given priority in admission, provided that the
                  directors of the Junior production can provide a good report
                  of your conduct within the group (see Code of Conduct).
                </li>

                <li>
                  B; if you were a member of a previous Dramabuzz Senior
                  production, you will be given priority admission, provided you
                  are in good standing with the group (see Code of Conduct).
                </li>

                <li>
                  C; if you are a completely new member, admission will be based
                  on an auditioning process as this will allow us to have a
                  benchmark of your abilities.
                </li>
              </ul>
            </li>

            <li>
              Admission to the group is not a guarantee of a main role. New
              members may be asked to take a chorus role or a supporting role in
              their first year, though this is up to the discretion of the
              directors.
            </li>

            <li>
              All members of the group agree that they will accept whatever role
              they are given. Failure to do so may result in not being allowed
              to join the group in future seasons.
            </li>

            <li>
              Without prior consent from the directors, non-members may not
              attend practices.
            </li>

            <li>
              Members will be expected to sign a contract upon joining the
              group, affirming that they understand and accept these rules.
            </li>

            <li>
              Married couples who wish to join the group must understand that
              their partner may receive a role that has a romantic interest who
              is not one of the partners and must be comfortable with that being
              a possibility.
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Practices and Practice Times</Heading2>

        <Heading4>
          <ul>
            <li>
              <strong>
                Practices will take place on Tuesday evenings from 19:30 -
                21:30.
              </strong>{" "}
              Practices will usually be once a week in the evenings, expect for
              extra practices that will be arranged as needed.
            </li>

            <li>
              Practice times are defined as anytime in which a practice has been
              arranged. This includes break times and the socialising before and
              after. Those who are not being used in rehearsals are expected to
              abide by the rules that apply during practice times.
            </li>

            <li>
              A member is expected to be at no less than 80% of the season's
              normal practices. Members are also expected to attend specially
              arranged extra practices throughout the season, unless otherwise
              arranged with the directors.
            </li>

            <li>
              All members are required to be at the practices arranged in the
              final two weeks of practice. These practices are where we are
              putting things together, and it is therefore paramount that
              everybody is there.
            </li>

            <li>
              All members are expected to attend the kickoff workshop day that
              will take place at the beginning of the season.
            </li>

            <li>
              Though it should go without saying, all members commit to be
              available during all show times.
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Code of Conduct</Heading2>

        <Heading4>
          Dramabuzz Senior holds the principles of a Christian worldview.
          Members are not expected to be confessing Christians to join the
          group. They will be expected to conduct themselves in a way that has
          been laid out in this code of conduct. Or code of conduct is as
          follows:
          <ul>
            <li>
              No vulgar language or crude humour will be permitted during
              practice times.{" "}
            </li>

            <li>
              The bullying of cast members will not be permitted under any
              circumstances. For the sake of clarity, this includes verbal abuse
              (ie. name calling, derision, or mockery) and physical abuse. If a
              member of the group has been bullied, the directors will intervene
              in the situation and will deal with the problem accordingly.
            </li>

            <li>
              Modest clothing is to be worn by both sexes. This means that the
              abdominal area, cleavage, wasteline and other such areas are to be
              covered at all times, even when moving and stretching. We
              encourage members to wear plain, black, comfortable clothing like
              joggers, sweatshirts, and tee-shirts as these will be the most
              conducive to practising. Clothing with vulgar graphics will not be
              allowed. If a member does not abide by the given dress code, they
              will receive a warning from the directors. Failure to comply after
              having received a warning could result in expulsion and in being
              barred from joining the group in future seasons.
            </li>

            <li>
              During practice times, cellphones are to be stored in a bag or out
              of sight. Practice times are for practising, and all members are
              expected to be present for the duration of the allotted time.
              Exceptions to this may be allowed at the discretion of the
              directors and with their permission. Such exceptions might
              include, but are not limited to:
              <ul>
                <li>
                  Using your phone to access the script (applicable only if the
                  script is electronic);
                </li>

                <li>Using your phone while you are not required on stage;</li>

                <li>Answering emergency calls or messages; or</li>

                <li>
                  any reasonable expectation that has been arranged with the
                  directors.
                </li>
              </ul>
            </li>

            <li>
              Eating during practice times is discouraged. Eating on stage while
              rehearsing is forbidden. We ask that members only use the provided
              break time to eat.
            </li>

            <li>
              Cast members will not be permitted to enter the change room of the
              opposite sex at any point during the productions, expect under
              instruction of the directors for a specific purpose. The privacy
              of all cast members must be honoured.
            </li>

            <li>
              Absence from practices without prior arrangement with the
              directors may result in a warning, or in some cases expulsion. We
              understand that things happen and that some people may not be able
              to make it to all practices. However, failure to communicate such
              changes of plan will not be tolerated.
            </li>

            <li>
              A member is to always be on good standing with the group. Failure
              to remain in good standing with the group may result in expulsion
              and in being barred from future seasons. This is defined as
              follows:
              <ul>
                <li>
                  A member must abide with all the rules of the above code of
                  conduct;
                </li>

                <li>
                  A member must submit fully to the authority and vision of the
                  directors (see On the Directors);
                </li>

                <li>
                  A member must perform in their given role to the best of their
                  ability, regardless of the size of the role;
                </li>

                <li>
                  A member must accept the role that is given to them with a
                  good attitude;
                </li>
              </ul>
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Membership & Fees</Heading2>

        <Heading4>
          Dramabuzz Senior is a non-profit group. All of our productions are
          self-funded and we therefore expect the following of our members.
          <ul>
            <li>
              Members must learn their lines. At the beginning of the rehearsal
              season, a deadline will be set for the cast to learn their words.
              Those of the cast who do have their words memorised by this
              deadline will face potential penalties.
            </li>

            <li>
              All of our props, costumes, and sets will be sourced by the group.
              Cast members will be expected to participate in full when it comes
              to work-parties and props days - the production is a team effort
              from rehearsals to the backstage management.
            </li>

            <li>
              Because we are self-funded have a joining fee of R500.00 payable
              upon acceptance into the cast. Payment arrangements can be
              organised if necessary at the discretion of the directors. This
              fee goes towards the various costs involved in putting a
              production together including venue hire, purchasing of materials,
              hiring of coaches and instructors if needed, and various other
              costs. A report of the spending of the group's funds may be
              requested from the directors.
            </li>
          </ul>
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>The Directors</Heading2>

        <Heading4>
          <ul>
            <li>
              The directors' word is final. They reserve the right to make
              changes to structure of the group, to provide exception to rules,
              or to in any way make allowances for members as they see fit;
              however
            </li>

            <li>
              The directors pledge to be fair and consistent with all members of
              the cast. The rules will apply equally to everyone, and
              favouritism among the directors will not be tolerated. To
              circumvent the possibility of such a thing happening, there will
              be a directorial committee made up of four members. Three of these
              members will be directly involved in the group and will have
              duties to carry out in putting the production together. However, a
              neutral external member will be appointed, someone who can lend an
              unbiased opinion in situations where needed.
            </li>

            <li>
              To avoid conflicts of interest, the directors will generally not
              participate as main actors in a production. This is to ensure that
              the directors are able to fully focus on their directorial duties.
              The rule is not hard and fast, and can be changed based at the
              directorial committee's discretion.
            </li>

            {/* <li>
              The directorial committee will be made up of the three following
              roles: The Director of Choreography and Movement, whose
              responsibility will be to ensure that scenes are blocked,
              choreography, and staged;
            </li>

            <li>
              The Director of Production, whose responsibility will be to ensure
              that the vocal production of scenes is up to scratch and that
              scenes are polished;
            </li>

            <li>
              The Director of Stage and Props, whose responsibility it will be
              to ensure that props and sets are organised and that the cast is
              using their props correctly.
            </li>

            <li>
              Each of these roles are malleable to a point, and crossover of
              responsibilities may happen. However, each director will have
              final say in the specific area that they are responsible for.
            </li> */}
          </ul>
        </Heading4>
      </ContentSection>

      <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock>
    </PageWrapper>
  );
}

import React from "react";
import { Heading2, Heading4 } from "../../theme/typography/headers";
import * as theme from "./Home.styles";
import { ContentSection } from "../../theme/layout/wrappers";
import { Navigation } from "../../components/navigation/navigation";
import { Header } from "../../components/header/Header";
import { ButtonBlock } from "../../components/button-block/button-block";
// import { ButtonBlock } from "../../components/button-block/button-block";

export function Home() {
  return (
    <theme.HomeWrapper>
      <Header
        programName="Toad of Toad Hall"
        year={2024}
        url={"/headers/2024.png"}
      />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>Our 2024 Production</Heading2>

        <Heading4>
          Dramabuzz Senior is delighted to announce that its 2024 production
          will be <strong>Toad of Toad Hall</strong>. This charming and
          delightful play is sure to knock your socks off.
        </Heading4>

        <Heading4>
          "Toad of Toad Hall" by A.A. Milne is a theatrical adaptation of
          Kenneth Grahame's classic "The Wind in the Willows." The play follows
          the adventures of the beloved characters—Mole, Rat, Badger, and the
          eccentric Toad of Toad Hall. The story centers on Toad's reckless
          passion for motorcars, which leads him into various escapades and
          legal troubles. The loyal friends band together to help Toad,
          navigating challenges and learning valuable lessons about friendship
          and responsibility along the way. Milne's adaptation captures the
          whimsy and charm of Grahame's original tale while bringing the
          characters to life on the stage.
        </Heading4>
      </ContentSection>

      <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock>

      {/* <ButtonBlock buttonLabel="Find out more" href="/2023" theme="primary">
        We are now beginning to open up auditions. If you are interested in
        joining us, we would love to have you. All the information you need can
        be found on the <strong>2023 page</strong>.
      </ButtonBlock> */}

      {/* <hr /> <ButtonBlock
        buttonLabel="Donate Now on Quicket"
        href="https://www.quicket.co.za/event/169825/collect"
        theme="quicket"
      >
        Thank you for coming to our show. We have put a lot of work into getting
        this together. We are a community driven, non-profit group which means
        that we are completely self funded and make no money off of our shows.
        <div style={{ height: 30 }} />
        If you can, please consider making a donation to our work.
      </ButtonBlock> */}

      <br />
    </theme.HomeWrapper>
  );
}

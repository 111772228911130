export const SynopsisList: { [key: string]: string } = {
  jack: `The play's protagonist. Jack Worthing is a seemingly responsible and respectable young man who leads a double life. In Hertfordshire, where he has a country estate, Jack is known as Jack. In London he is known as Ernest. As a baby, Jack was discovered in a handbag in the cloakroom of Victoria Station by an old man who adopted him and subsequently made Jack guardian to his granddaughter, Cecily Cardew. Jack is in love with his friend Algernon’s cousin, Gwendolen Fairfax. The initials after his name indicate that he is a Justice of the Peace.`,
  algernon: `The play’s secondary hero. Algernon is a charming, idle, decorative bachelor, nephew of Lady Bracknell, cousin of Gwendolen Fairfax, and best friend of Jack Worthing, whom he has known for years as Ernest. Algernon is brilliant, witty, selfish, amoral, and given to making delightful paradoxical and epigrammatic pronouncements. He has invented a fictional friend, “Bunbury,” an invalid whose frequent sudden relapses allow Algernon to wriggle out of unpleasant or dull social obligations.`,
  gwendolyn: `Algernon’s cousin and Lady Bracknell’s daughter. Gwendolen is in love with Jack, whom she knows as Ernest. A model and arbiter of high fashion and society, Gwendolen speaks with unassailable authority on matters of taste and morality. She is sophisticated, intellectual, cosmopolitan, and utterly pretentious. Gwendolen is fixated on the name Ernest and says she will not marry a man without that name.`,
  cecily: `Jack’s ward, the granddaughter of the old gentlemen who found and adopted Jack when Jack was a baby. Cecily is probably the most realistically drawn character in the play. Like Gwendolen, she is obsessed with the name Ernest, but she is even more intrigued by the idea of wickedness. This idea, rather than the virtuous-sounding name, has prompted her to fall in love with Jack’s brother Ernest in her imagination and to invent an elaborate romance and courtship between them.`,
  bracknell: `Algernon’s snobbish, mercenary, and domineering aunt and Gwendolen’s mother. Lady Bracknell married well, and her primary goal in life is to see her daughter do the same. She has a list of “eligible young men” and a prepared interview she gives to potential suitors. Like her nephew, Lady Bracknell is given to making hilarious pronouncements, but where Algernon means to be witty, the humor in Lady Bracknell’s speeches is unintentional. Through the figure of Lady Bracknell, Wilde manages to satirize the hypocrisy and stupidity of the British aristocracy. Lady Bracknell values ignorance, which she sees as “a delicate exotic fruit.” When she gives a dinner party, she prefers her husband to eat downstairs with the servants. She is cunning, narrow-minded, authoritarian, and possibly the most quotable character in the play.`,
  prism: `Cecily’s governess. Miss Prism is an endless source of pedantic bromides and clichés. She highly approves of Jack’s presumed respectability and harshly criticizes his “unfortunate” brother. Puritan though she is, Miss Prism’s severe pronouncements have a way of going so far over the top that they inspire laughter. Despite her rigidity, Miss Prism seems to have a softer side. She speaks of having once written a novel whose manuscript was “lost” or “abandoned.” Also, she entertains romantic feelings for Dr. Chasuble.`,
  chasuble: `The rector on Jack’s estate. Both Jack and Algernon approach Dr. Chasuble to request that they be christened “Ernest.” Dr. Chasuble entertains secret romantic feelings for Miss Prism. The initials after his name stand for “Doctor of Divinity"`,
  lane: `Algernon’s servant. When the play opens, Lane is the only person who knows about Algernon’s practice of “Bunburying.” Lane appears only in Act I.`,
  merriman: `The butler at the Manor House, Jack’s estate in the country. Merriman appears only in Acts II and III.`,
};

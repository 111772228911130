import styled from "styled-components";
import { Heading4 } from "../../theme/typography/headers";

export const FooterWrapper = styled.footer`
  display: block;
  width: 100%;
  padding: 3% 0;
  background: var(--text);
  text-align: center;

  ${Heading4} {
    color: white;

    @media screen and (max-width: 990px) {
      font-size: 12px;
    }
  }
`;

import styled from "styled-components";
import { Heading2, Heading4 } from "../../theme/typography/headers";
import { Paragraph } from "../../theme/typography/paragraph";

export const CastCardWrapper = styled.div`
  display: block;
`;

export const CastCardImage = styled.div`
  display: block;
  width: 100%;
  height: auto;
  padding-top: 100%;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const CastCardContent = styled.div`
  padding-top: 25px;
  text-align: center;
  padding-bottom: 15px;

  ${Heading2}, ${Heading4} {
    margin: 0 !important;
    padding: 0;
  }

  ${Paragraph} {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  ${Heading4} {
    font-size: 18px;
  }

  ${Heading2} {
    font-size: 30px;
    padding-bottom: 5px;
    line-height: 1;
    margin-bottom: 15px !important;

    @media screen and (max-width: 750px) {
      font-size: 18px;
      line-height: 1;
      margin-bottom: 10px !important;
    }

    &.isSmall {
      font-size: 25px;
    }
  }
`;

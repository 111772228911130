import styled from "styled-components";

export const ButtonWrapper = styled.a`
  display: inline-block;
  line-height: 48px;
  background: var(--text);
  position: relative;
  cursor: pointer;
  padding: 0 20px;
  vertical-align: middle;
  box-sizing: border-box;
  border: none;
  font-size: 20px;
  color: var(--background);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  box-shadow: 0px 0px 0px 0px var(--main-2);
  will-change: box-shadow;
  text-decoration: none;
  transition: box-shadow cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

  &:hover {
    box-shadow: 0px 0px 0px 5px var(--main-2);
  }

  &.quicket {
    background: var(--quicket);
    box-shadow: 0px 0px 0px 0px var(--quicket-2);

    &:hover {
      box-shadow: 0px 0px 0px 5px var(--quicket-2);
    }
  }

  &.accent {
    background: var(--main-3);
    color: var(--text);
    border: solid 2px var(--text);
    line-height: 40px;
    font-size: 18px;
    box-shadow: 0px 0px 0px 0px var(--main-1);

    &:hover {
      box-shadow: 0px 0px 0px 5px var(--main-1);
    }
  }

  @media screen and (max-width: 750px) {
    font-size: 16px;
  }
`;

import styled from "styled-components";

export const HeaderWrapper = styled.header`
  display: block;
  width: 90%;
  max-width: 1030px;
  margin: auto;
`;

export const HeaderLogo = styled.figure`
  margin: 0 auto;
  padding: 15px 0;
  display: block;

  img {
    display: block;
    width: 70%;
    max-width: 200px;
    margin: auto;
  }
`;

export const HeaderImage = styled.figure`
  margin: 0 auto;
  padding: 0;
  display: block;
  width: 70%;
  max-width: 450px;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const HeaderContent = styled.section`
  display: block;
  text-align: center;
`;

import React from "react";
import { CastCard } from "../../components/cast-card/cast-card";
import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import { Heading2, Heading4 } from "../../theme/typography/headers";
import { CardsWrapper, CardOuter } from "./the-team.styles";
import { ButtonBlock } from "../../components/button-block/button-block";

export function TheTeamPage(): JSX.Element {
  return (
    <PageWrapper>
      <TextHeader pageTitle="The Team" hideBottomImage />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>The Team</Heading2>

        <Heading4>
          In putting a show together, the production team is vitally important.
          From design to implementation, the production team helps to make the
          vision of the directors a reality and the world of the actors real.
        </Heading4>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/talitha.jpeg"
              isSmall
              memberName={"Talitha Komen"}
              roleName={"Creative Designer"}
            />
          </CardOuter>
        </CardsWrapper>

        <Heading4>
          If you are interested in joining our production team, we would love to
          have you on board. We always prefer to do things within the group, so
          if you are able to build or sew or do anything else that would be
          helpful in putting a show together, please contact us at{" "}
          <a href="mailto:admin@dramabuzzsenior.co.za">
            admin@dramabuzzsenior.co.za
          </a>
          .
        </Heading4>
      </ContentSection>

      <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock>
    </PageWrapper>
  );
}

import React from "react";
import { CastCard } from "../../../components/cast-card/cast-card";
import { HeaderLegacy } from "../../../components/header-legacy/HeaderLegacy";
import { ContentSection, PageWrapper } from "../../../theme/layout/wrappers";
import { Heading2, Heading4 } from "../../../theme/typography/headers";
import header from "./header.jpeg";
import { CardOuter, CardsWrapper } from "./program.styles";
import { SynopsisList } from "./synopsis";

export function ProgramPage(): JSX.Element {
  return (
    <PageWrapper>
      <HeaderLegacy isProgram url={header} programName="The Importance of Being Earnest" year={2022} />

      <hr />

      {/* <Navigation /> */}

      <ContentSection>
        <Heading2>Welcome</Heading2>

        <Heading4>
          Thank you for coming to see Dramabuzz Senior's debut production of The
          Importance of Being Earnest. The audience was incredible and the show
          was a stirling success. Here's to many many more.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>The Cast</Heading2>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/dylon.jpeg"
              memberName={"Dylon Webb"}
              roleName={"Jack Worthing"}
              characterSynopsis={SynopsisList.jack}
              bio={`Dylon Webb is 20 years old. Drama is a space where he can just breathe and feel free. His motto is "work hard and study hard", and He just loves meeting new people`}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/rebecca.jpeg"
              memberName={"Rebecca Britz"}
              roleName={"Gwendolyn Fairfax"}
              characterSynopsis={SynopsisList.gwendolyn}
              bio={
                "Rebecca is known for the gentleness of her disposition and the extraordinary sweetness of her nature. As a result, she suits the part of Gwendolen almost perfectly. Rebecca, however, loves tea cake way more than she likes bread and butter. This is what sets her apart from Gwendolen."
              }
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/jordan.jpeg"
              memberName={"Jordan Hibberd"}
              roleName={"Algernon Moncrieff"}
              characterSynopsis={SynopsisList.algernon}
              bio={
                "Jordan is 19 years old and started drama at the age of 13. He is incredibly passionate about music and loves acting. He is currently under an apprenticeship for an engineering company, but in his spare time, he enjoys reading, being physically active, seeking adventure, playing music and finding every excuse to be as dramatic as possible."
              }
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/tyler.jpeg"
              memberName={"Tyler Komen"}
              roleName={"Cecily Cardew"}
              characterSynopsis={SynopsisList.cecily}
              bio={`Tyler is 21 and very passionate about performing and is currently attending Stageworx Performing Arts school. She teaches drama at A+ Students and attends singing and dancing lessons. But don't let all this drama fool you she will also charm you with her cooking and painting skills. "Shoot for the moon. Even if you miss it you will land among the stars" -Les Brown`}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/andrea.jpeg"
              memberName={"Andrea Venter"}
              roleName={"Lady Bracknell"}
              characterSynopsis={SynopsisList.bracknell}
              bio={
                "Andrea is a self employed 22 year old that started helping out backstage at Dramabuzz Jr. at the age of 14. This is her first year on stage, her grand debut as it were. She grew up watching musicals and plays, so she am very excited to be a part of such a wonderful group!"
              }
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/chevaughn.jpeg"
              memberName={"Chevaughn van der Zandt"}
              roleName={"Miss Prism"}
              characterSynopsis={SynopsisList.prism}
              bio={
                "Chevaughn is 21 years old and started acting at the Waterfront Theatre School in Cape Town when she was just 8 years old. She is passionate about singing, acting and next generation development. Currently, she works as a tutor and enjoys reading, baking and attempting to write a novel in her spare time."
              }
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/joshua.jpeg"
              memberName={"Joshua Britz"}
              roleName={"Rev. Chasuble"}
              characterSynopsis={SynopsisList.chasuble}
              bio={
                "Joshua is 23 years old and has been involved in drama productions since the tender age of 11. He has a particular passion for theology, singing and musicals, coffee, plants, and technology. By day, he works as a software engineer. In his spare time, he enjoys reading, running, cooking, hiking, and drama (obviously)."
              }
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/casey.jpeg"
              memberName={"Casey-Jane Radford"}
              roleName={"Lane"}
              characterSynopsis={SynopsisList.lane}
              bio={
                "17 year old Casey Radford has been a drama queen since the age of 5. She is passionate about dancing, singing, drawing, acting and pretty much anything creative. She is a scholar at Kings school discovery where she can outlive her love for education."
              }
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/linmarie.jpeg"
              memberName={"Linmarie Dirks"}
              roleName={"Merriman"}
              characterSynopsis={SynopsisList.merriman}
              bio={
                "Linmarie Dirks is 19 and enjoys playing Guitar and Ukulele, watching Movies, Drama, and Acting. She would not be the person she is today if it wasn't for Jesus and her parents who raised her to be the person she was made to be. This is how we know what love is: Jesus Christ laid down his life for us. And we ought to lay down our lives for our brothers and sisters. ~ 1 John 3:16"
              }
            />
          </CardOuter>
        </CardsWrapper>
      </ContentSection>

      <ContentSection>
        <Heading2>The Directors</Heading2>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/tyler.jpeg"
              isSmall
              memberName={"Tyler Komen"}
              roleName={"Blocking and Staging"}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/andrea.jpeg"
              isSmall
              memberName={"Andrea Venter"}
              roleName={"Costumes and Sets"}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/joshua.jpeg"
              isSmall
              memberName={"Joshua Britz"}
              roleName={"Production and Admin"}
            />
          </CardOuter>
        </CardsWrapper>
      </ContentSection>

      <ContentSection>
        <Heading2>Play Synopsis</Heading2>

        <Heading4>
          Jack Worthing is a countryman who has invented a useful brother
          Earnest - who gets himself into the most dreadful scrapes - so that he
          can have an excuse to go into the city at any time he likes. While in
          the city, he stays with a close friend and confidant Algernon
          Moncrief, an illustrious and carefree gentleman. Algernon is the
          cousin of one Miss Gwendolyn Fairfax, a woman with whom Jack is deeply
          in love.
        </Heading4>

        <Heading4>
          During his most recent trip to London, Jack has decided to propose to
          Miss Fairfax and is delighted to discover that she is more than
          willing to accept his proposal, though only because she thinks his
          name is Earnest. Unperturbed by the reality that his name is not in
          fact Earnest, Jack is soaring on cloud nine until Gwendolyn's mother,
          Lady Bracknell, puts a damper on things by insisting that Jack must
          produce one living parent before she will agree to the proposal.
        </Heading4>

        <Heading4>
          A few days later, Jack returns to the country where he lives with his
          ward Cecily Cardew. He is shocked to find that Algernon is there under
          the pretence of being his brother, Earnest. Matters are made worse
          when it comes to light that Algernon and Cecily are in love. Just when
          Jack thinks that things could not get any worse, Lady Bracknell and
          Gwendolyn arrive and chaos ensues.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Thanks</Heading2>

        <Heading4>
          A performance like this takes a ton of work to get together. The
          directors (Tyler, Joshua, and Andrea) would like to extend a huge
          thank you to you for supporting us. We hope that you enjoyed your time
          with us.
        </Heading4>

        <Heading4>
          We would also like to give a big thank you to the cast. You have been
          amazing to work with - we look forward to many more years.
        </Heading4>

        <Heading4>
          We owe a debt of gratitude to Felicity Britz who founded the junior
          Dramabuzz group way back in 2009. Her work has inspired what we have
          done, and we would not have been able to have to success we have had
          without her support and the support of Dramabuzz. A very special
          thanks must also be given to Tyler Komen and Andrea Venter who started
          this whole venture off and got the group together.
        </Heading4>

        <Heading4>
          And finally, though not least by any means, we are thankful to the
          Lord who is the source of any talents and resources that we had to put
          this show together. Our performances are dedicated to His glory alone.
          We wish only that we be good stewards of want He has lent us.
        </Heading4>
      </ContentSection>

      {/* <ContentSection>
        <Heading2>How You Can Support Us</Heading2>

        <ButtonBlock
          noPadding
          alignLeft
          buttonLabel="Donate Now on Quicket"
          href="https://www.quicket.co.za/event/169825/collect"
          theme="quicket"
        >
          Thank you for coming to our show. We have put a lot of work into
          getting this together. We are a community driven, non-profit group
          which means that we are completely self funded and make no money off
          of our shows. If you can, please consider making a donation to our
          work.
        </ButtonBlock>
      </ContentSection> */}
    </PageWrapper>
  );
}

import styled from "styled-components";

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const ModalInner = styled.div`
  width: 90%;
  max-width: 650px;
  background: #ffffff;
  padding: 25px 25px;
  border-radius: 20px;
  max-height: 90%;
  overflow: auto;
`;

import React, { PropsWithChildren } from "react";
import { Heading2, Heading3, Heading4 } from "../../theme/typography/headers";
import { IDirectorCardProps } from "./director-card.models";
import {
  DirectorCardContent,
  DirectorCardImage,
  DirectorCardWrapper,
} from "./director-card.styles";

export function DirectorCard(
  props: PropsWithChildren<IDirectorCardProps>
): JSX.Element {
  return (
    <DirectorCardWrapper>
      <DirectorCardImage>
        <img
          src={props.imageUrl}
          alt={`Headshot of ${props.firstName} ${props.lastName}`}
        />
      </DirectorCardImage>

      <DirectorCardContent>
        <Heading3 className="role" style={{ marginBottom: -8, fontSize: 20 }}>
          Director of {props.role}
        </Heading3>

        <Heading2>
          {props.firstName} {props.lastName}
        </Heading2>

        <Heading4>{props.bio}</Heading4>

        <React.Fragment>{props.children}</React.Fragment>
      </DirectorCardContent>
    </DirectorCardWrapper>
  );
}

import React from "react";
import { Heading4 } from "../../theme/typography/headers";
import { FooterWrapper } from "./Footer.styles";

export function Footer() {
  return (
    <FooterWrapper>
      <Heading4>
        &copy; Dramabuzz Senior. All Rights Reserved |{" "}
        {new Date().getFullYear()}
      </Heading4>
    </FooterWrapper>
  );
}

import styled from "styled-components";

export const NavigationWrapper = styled.nav`
  position: sticky;
  top: 0;
  bottom: 0;
  padding: 1% 0;
  background: var(--background);
  z-index: 10;
  border-bottom: solid 2px var(--main-2);
  transform-origin: top right;

  @media screen and (max-width: 990px) {
    padding: 10px 0 10px 0;
    border-width: 1px;
  }

  &.active {
    position: fixed;
    bottom: auto;
    width: 100%;
  }

  &.light {
    background: var(--background);
    border-bottom: none;
  }
`;

export const NavigationLinksList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    a {
      display: block;
      line-height: 40px;
      font-size: 14px;
      padding: 0 15px;
      text-decoration: none;
      color: var(--text);
      /* white-space: nowrap; */
    }

    &:hover,
    &.active {
      background: var(--text);

      a {
        color: var(--background);
      }
    }
  }

  @media screen and (max-width: 990px) {
    position: fixed;
    top: 61px;
    left: 0;
    width: 100%;
    height: calc(100% - 61px);
    background: var(--background);
    flex-flow: column nowrap;
    gap: 20px;
    visibility: hidden;
    pointer-events: none;

    &.active {
      visibility: visible;
      pointer-events: all;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: none;
  margin: auto;
  width: 40px;
  height: 40px;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    background: none;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 990px) {
    display: block;
  }

  &.active {
    transform: rotate(90deg);
  }
`;

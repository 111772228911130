export const RoleDescriptionList: { [key: string]: string } = {
  henry: `Henry Higgins is a professor of phonetics who plays Pygmalion to Eliza Doolittle's Galatea. He is the author of Higgins' Universal Alphabet, believes in concepts like visible speech, and uses all manner of recording and photographic material to document his phonetic subjects, reducing people and their dialects into what he sees as readily understandable units. He is an unconventional man, who goes in the opposite direction from the rest of society in most matters. Indeed, he is impatient with high society, forgetful in his public graces, and poorly considerate of normal social niceties--the only reason the world has not turned against him is because he is at heart a good and harmless man. His biggest fault is that he can be a bully.`,
  eliza: `"She is not at all a romantic figure." So is she introduced in Act I. Everything about Eliza Doolittle seems to defy any conventional notions we might have about the romantic heroine. When she is transformed from a sassy, smart-mouthed kerbstone flower girl with deplorable English, to a (still sassy) regal figure fit to consort with nobility, it has less to do with her innate qualities as a heroine than with the fairy-tale aspect of the transformation myth itself. In other words, the character of Eliza Doolittle comes across as being much more instrumental than fundamental. The real (re-)making of Eliza Doolittle happens after the ambassador's party, when she decides to make a statement for her own dignity against Higgins' insensitive treatment. This is when she becomes, not a duchess, but an independent woman; and this explains why Higgins begins to see Eliza not as a mill around his neck but as a creature worthy of his admiration.`,
  colonel: `Colonel Pickering, the author of Spoken Sanskrit, is a match for Higgins (although somewhat less obsessive) in his passion for phonetics. But where Higgins is a boorish, careless bully, Pickering is always considerate and a genuinely gentleman. He says little of note in the play, and appears most of all to be a civilized foil to Higgins' barefoot, absentminded crazy professor. He helps in the Eliza Doolittle experiment by making a wager of it, saying he will cover the costs of the experiment if Higgins does indeed make a convincing duchess of her. However, while Higgins only manages to teach Eliza pronunciations, it is Pickering's thoughtful treatment towards Eliza that teaches her to respect herself.`,
  alfred: `Alfred Doolittle is Eliza's father, an elderly but vigorous dustman who has had at least six wives and who "seems equally free from fear and conscience." When he learns that his daughter has entered the home of Henry Higgins, he immediately pursues to see if he can get some money out of the circumstance. His unique brand of rhetoric, an unembarrassed, unhypocritical advocation of drink and pleasure (at other people's expense), is amusing to Higgins. Through Higgins' joking recommendation, Doolittle becomes a richly endowed lecturer to a moral reform society, transforming him from lowly dustman to a picture of middle class morality--he becomes miserable. Throughout, Alfred is a scoundrel who is willing to sell his daughter to make a few pounds, but he is one of the few unaffected characters in the play, unmasked by appearance or language. Though scandalous, his speeches are honest. At points, it even seems that he might be Shaw's voice piece of social criticism (Alfred's proletariat status, given Shaw's socialist leanings, makes the prospect all the more likely).`,
  mother: `Professor Higgins' mother, Mrs. Higgins is a stately lady in her sixties who sees the Eliza Doolittle experiment as idiocy, and Higgins and Pickering as senseless children. She is the first and only character to have any qualms about the whole affair. When her worries prove true, it is to her that all the characters turn. Because no woman can match up to his mother, Higgins claims, he has no interest in dallying with them. To observe the mother of Pygmalion (Higgins), who completely understands all of his failings and inadequacies, is a good contrast to the mythic proportions to which Higgins builds himself in his self-estimations as a scientist of phonetics and a creator of duchesses.`,
  freddy: `Higgins' surmise that Freddy is a fool is probably accurate. In the opening scene he is a spineless and resourceless lackey to his mother and sister. Later, he is comically bowled over by Eliza, the half-baked duchess who still speaks cockney. He becomes lovesick for Eliza, and courts her with letters. At the play's close, Freddy serves as a young, viable marriage option for Eliza, making the possible path she will follow unclear to the reader.`,
  pierce: `Higgins' extremely patient and long-suffering housekeeper. She has a natural motherly instinct and takes to Eliza straight away. During the six months of the bet, Mrs. Pearce sees to it that Eliza is well taken care of, despite Higgins' pig-headed nature.`,
};

export const BioList: { [key: string]: string } = {
  arno: `Arno is excited to be returning to the stage, 9 years after acting in Around the World in 80 days. Drama has played a huge role in his life, helping with confidence, creativity and public speaking. He is grateful to be saved by faith in Christ and His work on the cross. Though Colonel Pickering does not express many opinions in the play, Arno believes that the character of Pickering is very well matched to that of his own disposition. He also enjoys flirting with his wife on stage.`,
  clara: `Clara will be 19 in May. She is passionate about art and is taking online courses to become a children’s book illustrator and writer. In her spare time, she likes to read fantasy or regency books, anything by J.R.R Tolkien or Jane Austen. She also loves being outside and hiking. Drama is another recently acquired passion. She was in Drama Buzz Jr for two productions. She loved learning how to act and making backdrops. Clara is very excited to play the role of her name's sake Miss. Clara Eynsford Hill.`,
  andrea: `Andrea is 23 and has been helping backstage for Dramabuzz Junior since she was 14. This year she is the director of sets, props and costumes for Dramabuzz Senior and she is very excited for this year's production of Pygmalion! She studied Interior Decorating at the Blackford Institute of Design and has used her knowledge and expertise to help with set dressing. In her free time, Andrea likes to play piano, crochet and making glycerine soap.`,
  joshua: `Joshua is adventurer at heart with a passion for the outdoors - especially climbing, hiking, running, and caving. By day he works as a web developer and by night he sleeps (duh). When he get’s the chance, he enjoys reading fantasy or theology, cooking, making coffee, and singing. He has been doing drama since the tender age of 11 (although his mother might argue that he’s been doing it far longer than that).`,
  andrew: `Andrew is 19 years and this is his first time doing drama. He is currently studying sport science at Tuks which is a result for his love for sport, but to be more specifically, cricket. He will also play golf at any given opportunity. Even with his love for sport Andrew does enjoy taking part in new activities that aren’t related with sport. This is how he found an interest in some cultural activities such as choir, and is why you find him here in dramabuzz senior.`,
  rebecca: `Rebecca Britz is playing in her 13th Drama Buzz production. She has been acting since the ripe age of 8 and is now a master of the art. She is not as old as Mrs. Higgins, but she is just as fun. And although she has the patience of a saint, she is not afraid to use a book to put you back in place.`,
  gwenyth: `My name is Gwynneth and I am 18 years old. I started doing drama at the age of 12 and they haven't been able to pull me off the stage since. Career- wise i look forward to being able to learn as much as i can about the world of writing and performance once i have matriculated. In the meantime i am using my spare time to indulge in my passions such as; Acting , singing, writing and dancing. If not then you'll probably find me with my nose buried in a good book.`,
  talitha: `Talitha is 25 and joined drama bus junior at 12 and did 3 plays with them, this year she decided to get involved with Pygmalion and is excited to be involved. She studied at the SA school of weddings and has a passion for decorating at events and for holiday season, Talitha also has a passion for kids and went to Aupair in America. Her hobbies are painting, baking, beading and a variety of other crafts. She also has her own business Tally's Creations for making all sorts of crafts.`,
  tyler: `I am very passionate about drama. I have completed 2 degrees in performance arts, one in teaching and one in performing. I love creating from culinary delights, artworks and writing to producing. I write scripts and books and I one day hope to travel the world teaching and performing. Honesty is my core value. After Pygmalion I plan to direct a whole lot more. World here I come! "Set your goals high, and don't stop till you get there" - Bo Jackson`,
  danita: `This is Danita's first venture into acting, other than playing a mermaid at her nursery school and Mary in a nativity scene at her primary school. The whole process is exciting but also intimidating. She loves My Fair Lady and cannot wait for you all to enjoy our production of Pygmalion.`,
  cois: `Francois is a dynamic and invested person who seeks to do the best with everything he finds to do. Currently studying a BCom Project Management through Stadio University with the intention to re-enter the workforce as a PMP certified project manager or Estimator. His admiration for classic arts and a passion for excellence has lead him to interact with the stage in 7 junior drama production as main characters as well as 3 performances with professional dance companies. Francois relishes an opportunity to study a book, read his Bible, master a new guitar song, lift weights in the gym, go to a Ju-Jitsu class, and converse with friends & family.`,
};

import React from "react";
import {
  HeaderBottomCircle,
  HeaderInner,
  HeaderTopCircle,
  HeaderWrapper,
} from "./HeaderLegacy.styles";
import logo from "../../logo.svg";
import semi from "./semi.svg";
import semiUp from "./semi-up.svg";
import { Heading1, Heading6 } from "../../theme/typography/headers";
import classNames from "classnames";

export function HeaderLegacy(props: {
  url: string;
  title?: string;
  isProgram?: boolean;
  year?: number;
  programName?: string;
}) {
  return (
    <HeaderWrapper url={props.url}>
      <HeaderInner>
        <HeaderTopCircle>
          <object data={semi} type="image/svg+xml">
            No Image
          </object>

          <img src={logo} alt="Logo" />
        </HeaderTopCircle>

        <HeaderBottomCircle>
          <object data={semiUp} type="image/svg+xml">
            No Image
          </object>
          
          <div
            className={classNames("content", { isProgram: props.isProgram })}
          >
            <Heading6>
              Dramabuzz Senior Presents its {props.year} production of
            </Heading6>
            <Heading1>
              {props.programName}
            </Heading1>
          </div>
        </HeaderBottomCircle>
      </HeaderInner>
    </HeaderWrapper>
  );
}

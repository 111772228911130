import classNames from "classnames";
import React from "react";
import { ThemeColors } from "../../models/theme-colors";
import { ButtonWrapper } from "./Button.styles";

interface ButtonProps {
  label: string;
  href?: string;
  target?: string;
  rel?: string;
  theme?: ThemeColors;
}

type IntrinsicProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export function Button(props: ButtonProps & IntrinsicProps) {
  return (
    <ButtonWrapper {...(props as any)} className={classNames(props.theme)}>
      {props.label}
    </ButtonWrapper>
  );
}

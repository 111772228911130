import styled from "styled-components";

export const Paragraph = styled.p`
  font-family: "Cormorant", serif;
  font-size: 18px;
  font-weight: normal;
  padding-bottom: 30px;
  color: var(--dark-1);

  &:last-child {
    padding-bottom: 0;
  }
`;

export const Anchor = styled.a`
  font-weight: bold;
  color: var(--text);
  font-family: inherit;
`;

export const ArrowAnchor = styled(Anchor)`
  padding-right: 30px;
  position: relative;
  vertical-align: top;
  text-transform: uppercase;

  &::after,
  &::before {
    display: block;
    position: absolute;
    content: "";
  }

  &::after {
    width: 20px;
    height: 2px;
    background: var(--text);
    right: 0;
    top: 50%;
    margin-top: -1px;
  }

  &::before {
    width: 16px;
    height: 16px;
    border: solid 2px var(--text);
    border-bottom: none;
    border-left: none;
    right: 0;
    top: 50%;
    margin-top: -8px;
    transform: rotate(45deg);
  }
`;

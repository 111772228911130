import React from "react";
import { DirectorCard } from "../../components/director-card/director-card";
import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import { Heading2, Heading3, Heading4 } from "../../theme/typography/headers";
import { ButtonBlock } from "../../components/button-block/button-block";

export function TheDirectorsPage(): JSX.Element {
  function getAge(dateString: Date | string) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

    return age;
  }

  return (
    <PageWrapper>
      <TextHeader pageTitle="The Directors" hideBottomImage />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>Get to know the leadership</Heading2>

        <Heading4>
          Rather than having a single director, Dramabuzz Senior has a committee
          of three directors. This ensures that the directors are always held to
          account, but also means that there is greater freedom for creativity
          from them. The directors manage the general admin of the group and are
          responsible for seeing that everything gets done.
        </Heading4>

        <Heading4>
          The directors word is final in every production, this is to avoid
          endless arguments about what should or shouldn't be done. This does
          not, however, mean that the directors are closed to input from the
          cast. Especially in the early stages of production, cast input can be
          invaluable to the direction and tone of the show. The directors only
          ask that cast members respect and trust their vision for the
          production and submit to that fully.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>Who are the directors</Heading2>

        <Heading4>
          We have three different directors, each with a different focus within
          the show. Together, our directors organise and produce the show and
          ensure that nothing is neglected. There is of course much overlap
          between the directors - the roles are there as a means of creating
          responsibility. Each director has both on-stage and off-stage
          responsibilities
        </Heading4>

        <DirectorCard
          firstName={"Tyler"}
          lastName={"Komen"}
          role={"Blocking and Staging"}
          imageUrl={"/headshots/tyler.jpeg"}
          bio={`Tyler is ${getAge(
            new Date(2001, 0, 1)
          )} and very passionate about performing and is currently attending Stageworx Performing Arts school. She teaches drama at A+ Students and attends singing and dancing lessons. But don't let all this drama fool you she will also charm you with her cooking and painting skills. "Shoot for the moon. Even if you miss it you will land among the stars" -Les Brown`}
        >
          <Heading4>
            As the director of Blocking and Staging, Tyler is responsible for
            the big picture of the show. With the help of the other directors,
            she oversees and runs the blocking of each scene. She is responsible
            for making sure that each scene flows nicely into the next and that
            the cast's movement is natural and sensible. Her responsibilities
            are:
          </Heading4>

          <Heading3>Off-stage</Heading3>

          <Heading4>
            <ul style={{ marginTop: 0 }}>
              <li>Managing the group's chat groups;</li>
              <li>
                Assessing when extra practices are required and organising them;
              </li>
              <li>Planning the practice schedule for scenes each week;</li>
              <li>Planning dress rehearsals and run-throughs;</li>
            </ul>
          </Heading4>

          <Heading3>On-stage</Heading3>

          <Heading4>
            <ul style={{ marginTop: 0 }}>
              <li>Roughly blocking out all the scenes for the production;</li>
              <li>
                Spear-heading the run-throughs of scenes during practice times;
              </li>
              <li>
                Adjusting the staging and positioning of movement on the stage
                during practices;
              </li>
              <li>Creating and teaching choreography where required;</li>
              <li>Assisting actors in improving their characterisation;</li>
              <li>Production of the show;</li>
            </ul>
          </Heading4>
        </DirectorCard>

        <DirectorCard
          firstName={"Andrea"}
          lastName={"Venter"}
          role={"Costumes and Props"}
          imageUrl={"/headshots/andrea.jpeg"}
          bio={`Andrea is a self-employed ${getAge(
            new Date(2000, 0, 1)
          )} year old that started helping out backstage at Dramabuzz Jr. at the age of 14. She grew up watching musicals and plays, so she is very excited to be a part of such a wonderful group!`}
        >
          <Heading4>
            As the director of Costumes and Props, Andrea is responsible for
            making sure that the sets for a production all come together. It is
            her job to see that props and costumes are sourced, built, or made
            and to make sure that they fit with the general tone of the show.
            Her responsibilities are:
          </Heading4>

          <Heading3>Off-stage</Heading3>

          <Heading4>
            <ul style={{ marginTop: 0 }}>
              <li>
                Creating a list of props and costumes that will be needed for a
                production;
              </li>
              <li>
                Seeing that those costumes and props are sourced or created;
              </li>
              <li>
                Organising workshop days that will be dedicated for the cast to
                help out in the creation of props;
              </li>
              <li>
                Cataloguing and overseeing the storage of the props and
                costumes;
              </li>
            </ul>
          </Heading4>

          <Heading3>On-stage</Heading3>

          <Heading4>
            <ul style={{ marginTop: 0 }}>
              <li>
                Keeping track of where props are used within scenes and by who;
              </li>
              <li>Plotting out where the props need to be stored backstage;</li>
              <li>
                Overseeing the backstage crew during practices and performances;
              </li>
            </ul>
          </Heading4>
        </DirectorCard>

        <DirectorCard
          firstName={"Joshua"}
          lastName={"Britz"}
          role={"Production and Admin"}
          imageUrl={"/headshots/joshua.jpeg"}
          bio={`Joshua is ${getAge(
            new Date(1998, 5, 17)
          )} years old and has been involved in drama productions since the tender age of 11. He has a particular passion for theology, singing and musicals, coffee, plants, and technology. By day, he works as a software engineer. In his spare time, he enjoys reading, running, cooking, hiking, and drama (obviously).`}
        >
          <Heading4>
            As the director of Production and Admin, Joshua is responsible for
            the managerial side of the production. He also helps with fine
            tuning the show, assisting in polishing and adjusting scenes, and
            production. His responsibilities are:
          </Heading4>

          <Heading3>Off-stage</Heading3>

          <Heading4>
            <ul style={{ marginTop: 0 }}>
              <li>Managing and maintaining the group's website;</li>
              <li>Managing and maintaining the group's member list;</li>
              <li>Managing and maintaining the group's finances;</li>
              <li>Booking the practice performance venues;</li>
              <li>Setting up ticket sales;</li>
              <li>Creating advertisement campaigns, flyers, and graphics;</li>
            </ul>
          </Heading4>

          <Heading3>On-stage</Heading3>

          <Heading4>
            <ul style={{ marginTop: 0 }}>
              <li>Vocal coaching (ie. projection and vocal quality);</li>
              <li>Assisting the director of Blocking and Movement;</li>
              <li>Production of the show;</li>
            </ul>
          </Heading4>
        </DirectorCard>
      </ContentSection>

      <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock>
    </PageWrapper>
  );
}

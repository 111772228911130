export const RoleList: { [key: string]: string } = {
  rat: `Rat, a central character in "Toad of Toad Hall," epitomises the quintessential wide-eyed wonderer. His energy and curiosity excites his friends and endears him to even the least likeable animals of the countryside. He embodies qualities of loyalty, reliability, and a deep respect for tradition. Rat's tranquil existence by the riverbank is disrupted by the wild antics of his friend, Mr. Toad, yet he remains a steadfast companion, offering guidance and support amidst Toad's escapades. With his keen sense of adventure tempered by a grounded perspective, Rat brings a sense of balance to the group, helping them navigate the challenges they face while cherishing the beauty of their natural surroundings.`,
  toad: `Toad, the charismatic and flamboyant character in "Toad of Toad Hall," is the epitome of eccentricity and impulsiveness. Known for his extravagant tastes, boundless energy, and insatiable curiosity, Toad is the driving force behind many of the adventures in the play. His obsession with motorcars leads him into numerous scrapes and misadventures, much to the exasperation of his friends. Despite his flaws, Toad possesses a generous spirit and an irrepressible enthusiasm for life. His larger-than-life personality and penchant for drama make him both endearing and exasperating to those around him. Throughout the play, Toad's journey is one of growth and self-discovery as he learns valuable lessons about responsibility, humility, and the true meaning of friendship. Despite his occasional recklessness, Toad's charm and resilience ultimately win over the hearts of his companions and audiences alike.`,
  mole: `Mole, a gentle and timid character in "Toad of Toad Hall," embodies the innocence and curiosity of youth. Initially content with her quiet life underground, Mole's curiosity is piqued when she ventures out and discovers the wonders of the world above. Despite her initial trepidation, Mole embraces the beauty of the English countryside and forms deep bonds of friendship with Rat, Toad, and Badger. With her kind-hearted nature and willingness to see the best in others, Mole serves as a source of warmth and compassion within the group. Throughout the play, Mole's journey is one of self-discovery and growth as she learns to overcome her fears and embrace new experiences. Her gentle demeanour and unwavering loyalty endear her to her companions, making her an integral part of their adventures.`,
  badger: `Badger, a wise and respected character in "Toad of Toad Hall," represents the voice of reason and authority among his friends. As an elder statesman of the English countryside, Badger exudes an air of dignity and wisdom, earning the admiration and trust of his companions. He is depicted as a steadfast and reliable figure, offering guidance and support to his friends in times of trouble. Badger's deep connection to nature and his sense of responsibility towards his community make him a natural leader among the group. Despite his stern exterior, Badger possesses a kind and compassionate heart, always willing to lend a helping hand to those in need. Throughout the play, Badger serves as a mentor and role model to the younger characters, imparting valuable lessons about courage, integrity, and the importance of friendship. His presence adds depth and richness to the ensemble cast, embodying the timeless virtues of loyalty, strength, and wisdom.`,

  mother: `Professor Higgins' mother, Mrs. Higgins is a stately lady in her sixties who sees the Eliza Doolittle experiment as idiocy, and Higgins and Pickering as senseless children. She is the first and only character to have any qualms about the whole affair. When her worries prove true, it is to her that all the characters turn. Because no woman can match up to his mother, Higgins claims, he has no interest in dallying with them. To observe the mother of Pygmalion (Higgins), who completely understands all of his failings and inadequacies, is a good contrast to the mythic proportions to which Higgins builds himself in his self-estimations as a scientist of phonetics and a creator of duchesses.`,
  freddy: `Higgins' surmise that Freddy is a fool is probably accurate. In the opening scene he is a spineless and resourceless lackey to his mother and sister. Later, he is comically bowled over by Eliza, the half-baked duchess who still speaks cockney. He becomes lovesick for Eliza, and courts her with letters. At the play's close, Freddy serves as a young, viable marriage option for Eliza, making the possible path she will follow unclear to the reader.`,

  pierce: `Higgins' housekeeper. She attends to various duties around Higgins' house and serves to help Eliza adjust to the his eccentricities.`,
};

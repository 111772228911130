import styled from "styled-components";
import { PageWrapper } from "../../theme/layout/wrappers";

export const HomeWrapper = styled(PageWrapper)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  width: 100%;
  height: auto;
`;

export const ContentInner = styled.div`
  width: 90%;
  max-width: 1440px;
`;

export const ContentLogo = styled.div`
  width: 90%;
  max-width: 250px;
  margin: auto;

  object,
  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    max-width: 150px;
  }
`;

export const ContentText = styled.div`
  padding-top: 50px;
  text-align: center;
  text-transform: uppercase;
`;

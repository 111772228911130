import React from "react";
import { Navigation } from "../../components/navigation/navigation";
import { TextHeader } from "../../components/text-header/text-header";
import { ContentSection, PageWrapper } from "../../theme/layout/wrappers";
import { Heading4 } from "../../theme/typography/headers";
import { ButtonBlock } from "../../components/button-block/button-block";

export function AboutUsPage(): JSX.Element {
  return (
    <PageWrapper className="white">
      <TextHeader hideBottomImage pageTitle="About Us" />

      <hr />

      <Navigation />

      <ButtonBlock
        buttonLabel="Book Your Tickets"
        href="https://www.quicket.co.za/events/251146-toad-of-toad-hall/#/"
        theme="primary"
      >
        Tickets for Toad of Toad Hall are now on sale on Quicket. Don't miss
        Dramabuzz Senior's third show at{" "}
        <strong>Gereformeerde Kerk Noordrand</strong>.
      </ButtonBlock>

      <ContentSection>
        <Heading4>
          Way back in 2009,{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dramabuzz.co.za"
          >
            Dramabuzz
          </a>{" "}
          got its start thanks to the vision of Felicity Britz. As a
          homeschooling mother, she wanted to create a community where students
          could participate in theatre in a safe, fun, wholesome environment.
          Unfortunately, due to restrictions placed on the group by the
          production rights holders, students older than 18 cannot participate.
          This has meant that Dramabuzz has said many sad goodbyes over the
          years as students have outgrown the group.
        </Heading4>

        <Heading4>
          In 2022, many of these students will be excited to hear that they are
          not out of options. Dramabuzz Senior is an offshoot from Dramabuzz
          that aims to put on productions that have the same wholesome, family
          friendly value as the shows that the junior troupe puts on. The group
          is open to anyone who is 18 years or older, so all those despondent
          graduates can rejoice.
        </Heading4>

        <Heading4>
          We are a community driven group, which means that everything that we
          do is funded, put together, and organised by us. From sets and props,
          to costumes and make-up. We are incredibly grateful for the work that
          our members put into the group, we couldn't do it without them.
          Naturally, being self funded means that we don't have anybody
          bankrolling our productions. All of our ticket sales go towards future
          seasons. With this in mind the best way for you to support us is by
          coming to our shows and being an amazing audience member. However, if
          you would like to donate to our work, you can find more information on
          how to do so here.
        </Heading4>

        <Heading4>
          Finally, we are a group that is founded on Christian principles. This
          does not mean that our group is exclusively for Christians, but it
          does mean that we hold ourselves to the standards of conduct set forth
          in the Bible. Foremost of these is the principle of Sola Deo Gloria -
          Glory to God Alone (Ecclesiastes 9:10, 1 Corinthians 10:31).
          Everything we do is done to our Creator's glory above all else. This
          idea works its way into the rest of our group structure and is the
          foundation upon which our code of conduct is built.
        </Heading4>
      </ContentSection>

      {/* <ContentSection>
        <Heading2>How You Can Support Us</Heading2>

        <ButtonBlock
          noPadding
          alignLeft
          buttonLabel="Donate Now on Quicket"
          href="https://www.quicket.co.za/event/169825/collect"
          theme="quicket"
        >
          Of course, the ultimate way you can support us is by purchasing show
          tickets and being and awesome audience member. Outside of that, we
          also have a way to give donations to the group, using the quicket
          platform. We are also open to sponsorships. If you are interested in
          becoming a sponsor, please email us at{" "}
          <a
            href="mailto:sponsor@dramabuzzsenior.co.za"
            style={{ wordBreak: "break-all" }}
          >
            sponsor@dramabuzzsenior.co.za
          </a>
          for more information.
        </ButtonBlock>
      </ContentSection> */}

      {/* <ContentSection>
        <Heading2>How You Can Support Us</Heading2>

        <Heading4>
          Of course, the ultimate way you can support us is by purchasing show
          tickets and being and awesome audience member. Outside of that, we
          also have a way to give donations to the group, using the quicket
          platform. We are also open to sponsorships. If you are interested in
          becoming a sponsor, please email us at <a href="mailto:sponsor@dramabuzzsenior.co.za" style="white-space: break-word">sponsor@dramabuzzsenior.co.za</a>
          for more information.
        </Heading4>
      </ContentSection> */}
    </PageWrapper>
  );
}

import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Heading4 } from "../../theme/typography/headers";
import { Button } from "../button/Button";
import { IButtonBlockProps } from "./button-block.props";
import { ButtonBlockWrapper } from "./button-block.styles";

export function ButtonBlock(
  props: PropsWithChildren<IButtonBlockProps>
): JSX.Element {
  const { alignLeft, noPadding } = props;

  return (
    <ButtonBlockWrapper className={classNames({ alignLeft, noPadding }, props.theme)}>
      <Heading4>{props.children}</Heading4>
      
      <div style={{ textAlign: "center" }}>
        <Button
          label={props.buttonLabel}
          href={props.href}
          rel="noopener noreferrer"
          target="_blank"
          theme={props.theme}
        />
      </div>
    </ButtonBlockWrapper>
  );
}

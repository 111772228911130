import styled from "styled-components";
import { Heading3 } from "../../theme/typography/headers";

export const DirectorCardContent = styled.div`
  grid-area: content;
`;

export const DirectorCardContentOutlet = styled.div``;

export const DirectorCardImage = styled.figure`
  grid-area: image;
  display: block;
  padding: 0;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    border-radius: 50%;
  }
`;

export const DirectorCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-areas: "image content";
  gap: 40px;
  padding-bottom: 30px;
  padding-top: 20px;

  @media screen and (max-width: 930px) {
    grid-template-columns: 200px 1fr;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-areas: "image" "content";
    text-align: center;

    ${Heading3}, ul {
      text-align: left;
    }

    ${Heading3}.role {
      text-align: center;
    }

    ${DirectorCardImage} {
      max-width: 300px;
      width: 100%;
    }
  }
`;

import React from "react";
import { ProgramPage } from "./2022/program.page";
import { Year2023 } from "./2023/2023";
import { Year2024 } from "./2024/2024";

export function Years(props: { year: number }): JSX.Element {
  switch (props.year) {
    case 2022:
      return <ProgramPage />;
    case 2023:
      return <Year2023 />;
    case 2024:
      return <Year2024 />;

    default:
      return <React.Fragment>Nothing</React.Fragment>;
  }
}

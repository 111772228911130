import classNames from "classnames";
import React, { useState } from "react";
import {
  ButtonWrapper,
  NavigationLinksList,
  NavigationWrapper,
} from "./navigation.styles";

export function Navigation(props: { light?: boolean }): JSX.Element {
  const { pathname } = window.location;
  const [isActive, setIsActive] = useState(false);
  const { light } = props;

  return (
    <NavigationWrapper className={classNames({ active: isActive, light })}>
      <NavigationLinksList className={classNames({ active: isActive })}>
        <li className={classNames({ active: pathname === "/" })}>
          <a href="/">Home</a>
        </li>

        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.quicket.co.za/events/251146-toad-of-toad-hall#/"
          >
            Book Tickets
          </a>
        </li>

        <li className={classNames({ active: pathname === "/2024" })}>
          <a href="/2024">2024</a>
        </li>

        <li
          className={classNames({ active: pathname === "/structure/about-us" })}
        >
          <a href="/structure/about-us">About Us</a>
        </li>

        <li
          className={classNames({
            active: pathname === "/structure/the-directors",
          })}
        >
          <a href="/structure/the-directors">Our Directors</a>
        </li>

        {/* <li
          className={classNames({ active: pathname === "/structure/the-team" })}
        >
          <a href="/structure/the-team">Our Team</a>
        </li> */}

        <li className={classNames({ active: pathname === "/our-policies" })}>
          <a href="/our-policies">Our Policies</a>
        </li>

        {/* <li className={classNames({ active: pathname === "/donate" })}>
          <a href="/donate">Donate</a>
        </li> */}
      </NavigationLinksList>

      <ButtonWrapper
        className={classNames({ active: isActive })}
        onClick={() => setIsActive(!isActive)}
      >
        <button>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="8" y="22" width="85" height="10" rx="5" fill="#6B3F14" />
            <rect x="8" y="45" width="85" height="10" rx="5" fill="#6B3F14" />
            <rect x="8" y="68" width="85" height="10" rx="5" fill="#6B3F14" />
          </svg>
        </button>
      </ButtonWrapper>
    </NavigationWrapper>
  );
}

import React from "react";
import { CastCard } from "../../../components/cast-card/cast-card";
import { HeaderLegacy } from "../../../components/header-legacy/HeaderLegacy";
import { Navigation } from "../../../components/navigation/navigation";
import { PageWrapper, ContentSection } from "../../../theme/layout/wrappers";
import { Heading2, Heading4 } from "../../../theme/typography/headers";
import {
  CardsWrapper,
  CardOuter,
} from "../../the-directors/the-directors.styles";
import { BioList, RoleDescriptionList } from "./2023.data";

export function Year2023(): JSX.Element {
  return (
    <PageWrapper className="white">
      <HeaderLegacy
        isProgram
        url={"/headers/2023.png"}
        programName="Pygmalion"
        year={2023}
      />

      <hr />

      <Navigation />

      <ContentSection>
        <Heading2>Welcome</Heading2>

        <Heading4>
          Dramabuzz Senior is delighted to announce that its 2023 production
          will be <strong>Pygmalion</strong>. We hope that this witty and
          brilliant play by George Bernard Shaw will delight and entertain you.
        </Heading4>

        <Heading4>
          This satirical and hilarious play by George Bernard Shaw does not
          distract from its didactic nature. The story is set in London in the
          early 1900s. Though the name might not be familiar to you, the musical
          version of the show - My Fair Lady - has long stood as a beloved
          classic.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>About the Play</Heading2>

        <Heading4>
          Pygmalion is a romance in five parts by George Bernard Shaw, produced
          in German in 1913 in Vienna. It was performed in England in 1914, with
          Mrs. Patrick Campbell as Eliza Doolittle. The play is a humane comedy
          about love and the English class system.
        </Heading4>

        <Heading4>
          Henry Higgins, a phonetician, accepts a bet that simply by changing
          the speech of a Cockney flower seller he will be able, in six months,
          to pass her off as a duchess. Eliza undergoes grueling training. When
          she successfully “passes” in high society—having in the process become
          a lovely young woman of sensitivity and taste—Eliza realises that
          Higgins does not see her, but only what he has created. Coming to see
          that Higgins will never change and now being equipped to handle
          herself, Eliza confronts Higgins about her situation and questions
          what she is going to do next. Higgins is hurt by the encounter and the
          two part non-amiably. The next day, the two come to a head again as
          Higgins attempts to convince Eliza to return to the way things were
          during the experiment.
        </Heading4>
      </ContentSection>

      <ContentSection>
        <Heading2>The Cast</Heading2>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/francois2022.jpeg"
              roleName={"Prof. Henry Higgins"}
              memberName="Francois Venter"
              characterSynopsis={RoleDescriptionList.henry}
              bio={BioList.cois}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/gwennyth2022.jpeg"
              roleName={"Eliza Doolittle"}
              characterSynopsis={RoleDescriptionList.eliza}
              memberName="Gwynneth Thomas"
              bio={BioList.gwenyth}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/arno2022.jpeg"
              roleName={"Colonel Pickering"}
              characterSynopsis={RoleDescriptionList.colonel}
              memberName="Arno van den Bergh"
              bio={BioList.arno}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/josh2023.jpeg"
              roleName={"Alfred Doolittle"}
              characterSynopsis={RoleDescriptionList.alfred}
              memberName="Joshua Britz"
              bio={BioList.joshua}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/andrew2022.jpeg"
              roleName={"Freddy Eynsford-Hill"}
              characterSynopsis={RoleDescriptionList.freddy}
              memberName="Andrew Clark"
              bio={BioList.andrew}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/danita2022.jpeg"
              roleName={"Mrs. Pearce/Bystander"}
              characterSynopsis={RoleDescriptionList.pierce}
              memberName="Danita van den Bergh"
              bio={BioList.danita}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/talitha2022.jpeg"
              roleName={"Mrs. Eynsford-Hill"}
              characterSynopsis={`Freddy's mother`}
              memberName="Talitha Komen"
              bio={BioList.talitha}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/clara2022.jpeg"
              roleName={"Clara Eynsford-Hill"}
              characterSynopsis={`Freddy's sister`}
              memberName="Clara Qualls"
              bio={BioList.clara}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/rebecca2023.jpeg"
              roleName={"Mrs. Higgins"}
              characterSynopsis={`Henry Higgins' mother`}
              memberName="Rebecca Britz"
              bio={BioList.rebecca}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/andrea.jpeg"
              roleName={"Parlour maid"}
              memberName="Andrea Venter"
              bio={BioList.andrea}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/tyler.jpeg"
              roleName={"Sarcastic Bystander"}
              memberName="Tyler Komen"
              bio={BioList.tyler}
            />
          </CardOuter>

          <CardOuter />
        </CardsWrapper>
      </ContentSection>

      <ContentSection>
        <Heading2>The Directors</Heading2>

        <Heading4>
          No show comes together on its own. Months of planning and admin work
          go into choosing a play, setting up practices, hosting auditions, and
          putting everything together. This is the responsibility of our
          directors.
        </Heading4>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/tyler.jpeg"
              isSmall
              memberName={"Tyler Komen"}
              roleName={"Blocking and Staging"}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/andrea.jpeg"
              isSmall
              memberName={"Andrea Venter"}
              roleName={"Costumes and Sets"}
            />
          </CardOuter>

          <CardOuter>
            <CastCard
              url="/headshots/josh2023.jpeg"
              isSmall
              memberName={"Joshua Britz"}
              roleName={"Production and Admin"}
            />
          </CardOuter>
        </CardsWrapper>
      </ContentSection>

      <ContentSection>
        <Heading2>The Production Team</Heading2>

        <Heading4>
          In putting a show together, the production team is vitally important.
          From design to implementation, the production team helps to make the
          vision of the directors a reality and the world of the actors real.
        </Heading4>

        <CardsWrapper>
          <CardOuter>
            <CastCard
              url="/headshots/talitha.jpeg"
              isSmall
              memberName={"Talitha Komen"}
              roleName={"Creative Designer"}
            />
          </CardOuter>
        </CardsWrapper>
      </ContentSection>
    </PageWrapper>
  );
}

import React from "react";
import { Heading1 } from "../../theme/typography/headers";
import {
  HeaderWrapper,
  HeaderInner,
  HeaderTopCircle,
  HeaderBottomCircle,
} from "./text-header.styles";
import semi from "./semi.svg";
import semiUp from "./semi-up.svg";
import logo from "../../logo.svg";

export function TextHeader(props: {
  pageTitle?: string;
  hideBottomImage?: boolean;
}): JSX.Element {
  const { pageTitle = "Generic Page" } = props;

  return (
    <HeaderWrapper>
      <HeaderInner>
        <HeaderTopCircle>
          <object data={semi} type="image/svg+xml">
            No Image
          </object>

          <img src={logo} alt="Logo" />
        </HeaderTopCircle>

        <Heading1>{pageTitle}</Heading1>

        {!props.hideBottomImage && (
          <HeaderBottomCircle>
            <object data={semiUp} type="image/svg+xml">
              No Image
            </object>
          </HeaderBottomCircle>
        )}
      </HeaderInner>
    </HeaderWrapper>
  );
}
